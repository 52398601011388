import "../../css/font-icons.css";
import "../../css/dark.css";

function Footer() {
  return (
    <>

      <div id="footer" className="dark" style={{ backgroundColor: "rgb(92,64,51" ,position:""}}>
        <div className="">
          <div className="footer-widgets-wrap1">
            <div className="row">
            <div className="col-md-6 col-lg-1">
            </div>
              <div className="col-lg-4" style={{ position:"static"}}>
              <div className="footer_VM">
              <h3>Mission</h3>
                  <p>
                  To foster Ethiopia's sustainable development by planting seedlings in urban and rural landscapes to improve socio-economic well-being and ecological resilience.
                  </p>
                </div>
              </div>

              <div className="col-lg-4"style={{ position:"static"}}>
                <div className="footer_VM" >
                  <h3>Vision</h3>
                  <p>
                  Ensure Ethiopia's prosperity through restoration of degraded landscapes, increasing forest cover, enhancing water resource availability, improving food and nutrition security.

                  </p>
                
                
                </div>
              </div>

              <div className="col-lg-3">
              <div className="">
                    
                      
                    <strong>Phone:</strong>
                    <br/>
                  
                  (251) 968-59-77-84
                  <br />
                  (251) 947-85-61-75
                    <br/>
                  
                  <abbr title="Email Address">
                    <strong>Email:</strong>
                    <br/>
                  </abbr>{" "}
                  info@ssgi.gov.et
                </div>
                <div className="quick-contact-widget form-widget footer_VM">
                  <div className="">
                    <a
                      href="https://www.facebook.com/esstiethiopia"
                      className="social-icon si-small si-rounded si-facebook"
                      target="_blank"
                    rel="noopener noreferrer"
                    >
                      <i className="icon-facebook"></i>
                      <i className="icon-facebook"></i>
                    </a>

                    <a
                      href="https://twitter.com/essgi2022"
                      className="social-icon si-small si-rounded si-twitter"
                      target="_blank"
                    rel="noopener noreferrer"
                    >
                      <i className="icon-twitter"></i>
                      <i className="icon-twitter"></i>
                    </a>

                    <a
                      href="https://www.youtube.com/channel/UCiFzlJraHEdMPH6FikAMHww"
                      className="social-icon si-small si-rounded si-gplus"
                      target="_blank"
                    rel="noopener noreferrer"
                    >
                      <i className="icon-youtube"></i>
                      <i className="icon-youtube"></i>
                    </a>

                    <a
                      href="https://t.me/spacegeospatial"
                      className="social-icon si-small si-rounded si-yahoo"
                      target="_blank"
                    rel="noopener noreferrer"
                    >
                      <i className="icon-telegram"></i>
                      <i className="icon-telegram"></i>
                    </a>

                    <a
                      href="https://www.linkedin.com/in/space-science-and-geospatial-institute-37b48623a/?originalSubdomain=et"
                      className="social-icon si-small si-rounded si-linkedin"
                      target="_blank"
                    rel="noopener noreferrer"
                    >
                      <i className="icon-linkedin"></i>
                      <i className="icon-linkedin"></i>
                    </a>

                    <a
                    href="https://www.instagram.com"
                    className="social-icon si-small si-rounded si-instagram"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="icon-instagram"></i>
                    <i className="icon-instagram"></i>
                  </a>

                  </div>
                  {/* Address info */}
                 
                </div>
                
              </div>
             
             
            </div>
          </div>
        </div>

        <div style={{ backgroundColor: "#3c2e03" ,color :"white"}}>
        <div style={{ backgroundColor: "#3c2e03" ,color :"white"}}>
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-12 text-center">
              <div className="col-12 text-center">
                <a href="http://ssgi.gov.et">
                  <p style={{ color :"white"}}>
                    Copyrights &copy; 2024 All Rights Reserved by SSGI.
                  </p>
                </a>
              </div>

             
             
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>

    </>
  );
}

export default Footer;
